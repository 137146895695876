body {

  font-family: "Work Sans", sans-serif;
}


/* Style for Top Header */
.topheader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.topheader {
  width: 100%;
  height: 50px;
  background-color: #006E6B;
}

.topheader-social-icons {
  color: white;
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.link-container {
  display: flex;
  gap: 8px;
  margin-left: 20px;
}

.custom-link {
  text-decoration: none;
  color: white;
}


.custom-link:hover {
  color: #b4b4b4;
}

.dropdwon-language {
  z-index: 2000;

}

.links-main-Topheader {
  display: flex;
  align-items: center;
}


.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

/* Style for Header maIN */


.whatsapp-logo {
  position: fixed;
  bottom: 30px;
  left: 28px;
  z-index: 1000;
}

.whatsapp-image {
  width: 65px; 
  height: 65px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.whatsapp-image:hover {
  transform: scale(1.2);
}

.header-links {
  display: flex;
}

.Links-main-header {
  text-decoration: none;
  color: #ED2B2A;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.Links-main-header::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #006e6b;
  transition: width 0.3s ease-in-out;
}

.Links-main-header:hover::before {
  width: 100%;
}

.Links-main-header:hover {
  color: #006e6b;
}

.header-navbar {
  position: absolute;
  top: 50;
  width: 100%;
  background-color: white;
  z-index: 1000;
  transition: 0.3s;
}

.header-navbar.fixed {
  position: fixed;
  box-shadow: 0px 1px 4px 1px rgba(114, 114, 114, 0.959);
  background-color: white;
  top: 0px;
}


/* Addto Cart */
.cart-icon-container {
  position: relative; 
  display: inline-block;
}

.la-shopping-cart {
  text-decoration: none;
  color: #006e6b;
  font-size: 35px;
  border: 2px solid #006E6B;
  border-radius: 50px;
  padding: 1px;
  cursor: pointer;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px; 
  background-color: #ff0000; 
  color: #ffffff;
  border-radius: 50%;
  width: 20px; 
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; 
}


.add-to-cart-container {
  position: relative; 
  display: inline-block;
}

.custom-dropdown-menu {
  position: absolute;
  top: 35px;
  right: 0;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #a8a8a8;
  box-shadow: 0 1px 5px rgb(73, 73, 73);
  z-index: 1000;
  width: 300px; /* slightly enlarged */
  padding: 10px;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  color: white;
  border: none; 
  cursor: pointer;
}
.button-minus{
  font-size: 18px;
  border-radius: 3px;
  padding-bottom: 5px;
  width: 25px;
  height: 28px;
  background-color: #ED2B2A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-plus{
  font-size: 18px;
  border-radius: 3px;
  width: 25px;
  height: 28px;
  background-color: #F3CA52;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantity-controls span {
  margin: 0 10px;
}

.dropdown-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.clear-btn, .order-btn {
  background-color: #006e6b;
  color: white;
  border: none;
  padding: 5px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

/* Coursel Image Style  */

/* coursel image 1 */
.courselimage-1 {
  width: 100%;
  height: 750px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #222222a8 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* coursel image 2 */
.courselimage-2 {
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #00000096 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* coursel image 3 */
.courselimage-3 {
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #0f0f0f96 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* coursel image 4 */
.courselimage-4 {
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #0f0f0f96 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* coursel image 5 */
.courselimage-5 {
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #0f0f0f96 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-7.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* coursel image 6 */
.courselimage-6 {
  position: relative;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-background-courselimage-6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #0f0f0f96 100%, #e9202749 0%), url("../../../public/img/Corsols/coursol-8.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: 1;
}

/* Custom CSS to remove the default carousel indicators */
.carousel-indicators {
  display: none;
}

/* Style for the carousel captions */
.coursel-homeadd {
  margin-top: 65px;
}

.coursel-Text {
  height: 500px;
  width: 1400px;
  position: relative;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slideInRight 1.5s ease-in-out;
}

.coursel-2text-1 {
  color: #ED2B2A;
  font-size: 25px;
  font-weight: 600;
}

.coursel-2text-2 {
  color: #ffffff;
  font-size: 120px;
  font-weight: 600;
  line-height: 120px;
  width: 900px;
}

/* Animation for sliding text from right to left */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.button-coursel {
  width: 400px;
  height: 100px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.button-coursel .btn-main-coursel {
  width: 160px;
  height: 40px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  color: white;
  border: 2px solid #ED2B2A;
  background-color: #ED2B2A;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.button-coursel .btn-main-coursel:hover {
  width: 160px;
  height: 40px;
  text-decoration: none;
  color: #ED2B2A;
  font-weight: 500;
  border: 2px solid #ED2B2A;
  background-color: #ffffff;
}


/* Style for Session Time-Picup-Delever  */

.session2-main {
  padding-top: 70px;
  background-color: #F2F2F2;
  height: auto;
  padding-bottom: 10px;
  width: 100%;
}

.session-2-row-main {
  display: flex;
  justify-content: center;
}

.session2-container {
  display: flex;
  justify-content: space-evenly;
}

.session2-time {
  width: 350px;
  height: 90px;
  background-color: white;
  border-radius: 5px;
}

.time-row {
  height: 100%;
}

.time-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock {
  position: relative;
  height: 50px;
  width: 50px;
  background: white;
  border: 2px solid #6d6d6d;
  border-radius: 100%;
  margin: auto;
}

.clock .center {
  height: 6px;
  width: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #6d6d6d;
  border-radius: 100%;
}

.clock .hour {
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: hourRotation 24s infinite linear;
}

.clock .hour:before {
  position: absolute;
  content: "";
  background: #006e6b;
  height: 20px;
  width: 3px;
  top: 22px;
}

.clock .minute {
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: minuteRotation 16s infinite linear;
}

.clock .minute:before {
  position: absolute;
  content: "";
  background: #000000;
  height: 20px;
  width: 2px;
  top: 10px;
}

.clock .second {
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: secondRotation 8s infinite linear;
}

.clock .second:before {
  position: absolute;
  content: "";
  background: #ED2B2A;
  height: 22px;
  width: 2px;
  top: 0px;
}

@keyframes hourRotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes minuteRotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes secondRotation {
  to {
    transform: rotate(360deg);
  }
}

.timingtable-main {
  height: 35px;
  font-weight: 700;
  color: #6d6d6d;
  line-height: 10px;
}


/* Style fo0r Address */

.address-main {
  width: 400px;
  height: 90px;
  background-color: white;
  border-radius: 5px;
  text-decoration: none;
  color: #6d6d6d
}

.Link-address-main {
  text-decoration: none;
  color: #6d6d6d
}

.address-row {
  height: 100%;
}

.address-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-col .la-map-marker-alt {
  font-size: 40px;
  color: #006e6b;

}

.address-col p {
  font-size: 20px;
  padding-top: 10px;
  width: 100%;
}

/* Style for Delevary components */

.delivary-main {
  width: 360px;
  height: 90px;
  background-color: white;
  border-radius: 5px;
  text-decoration: none;
  color: #6d6d6d
}

.delevary-row {
  height: 100%;
}

.delevary-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.la-truck-moving {
  font-size: 40px;
  color: #006e6b;
}

.delevary-col p {
  font-size: 15px;
  padding-top: 12px;
  width: 200px;
}

/* Style for Model Component */

.model-main {
  display: flex;
}

.input-l-f-name{
  display: flex;
  justify-content: space-between;
}
.input-phoneno-model-signup{
  width:60%;
}
.name-inputs-mainfl{
  width: 49%;
}
.mode-tital {
  margin-left: 20px;
  margin-top: 10px;
}

.close-link-model {
  text-decoration: none;
  color: #000000;
  font-size: 25px;
  height: 40px;
  width: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-link-model:hover {
  color: #ED2B2A;
}

.model1-main-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.model1-second {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #ED2B2A;
  height: 40px;
  border-radius: 10px;
}

.deleviry-model-1-btn-1 {
  width: 148px;
  height: 100%;
  border-right: 2px solid #ED2B2A;
  text-align: center;
  padding-top: 5px;
  background-color: #ED2B2A;
  color: white;
  text-decoration: none;
  font-size: 17px;
  transition: 0.3s;
}

.deleviry-model-1-btn-2 {
  width: 148px;
  height: 100%;
  text-align: center;
  padding-top: 5px;
  background-color: #ffffff;
  border-radius: 10px;
  color: rgb(255, 0, 0);
  text-decoration: none;
  transition: 0.3s;
  font-size: 17px;
}

.deleviry-model-1-btn-2:hover {
  background-color: #ED2B2A;
  color: rgb(255, 255, 255);
  border-radius: 0%;
}

.model-search-bar-main {
  width: 100%;
  margin-top: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-search-bar-main-modeladdress{
  width: 100%;
  margin-top: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-search-bar-main-usereditmodel{
  width: 100%;
  margin-top: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;                                                     
}
.model-search-bar {
  width: 85%;
  border-radius: 5px;
  border: 1px solid #006e6b;
  height: 40px;
}

.model-search-bar:focus {
  border-color: #006e6b;
  outline: none;
}
.form-model-buynow .form-label{
  margin-top: 10px;
}
.form-label{
  margin-left: 10px;
}
.location-results {
  max-height: 180px;
  overflow-y: auto;
}

.location-list {
  list-style-type: none;
  padding-left: 0;
}

.location-list li {
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.model-link-save {
  width: 80%;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #f2f2f2;
  border: 2px solid #ED2B2A;
  background-color: #ED2B2A;
  font-size: 18px;
  font-weight: 400;
}

.custom-modal .modal-content {
  max-height: 600px;
  overflow-y: auto;
}
.fade{
  background-color: #0000003b;
}
.deleviry-model-2-btn-1 {
  width: 148px;
  height: 100%;
  text-align: center;
  padding-top: 5px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #ED2B2A;
  text-decoration: none;
  transition: 0.3s;
  font-size: 17px;
}

.deleviry-model-2-btn-1:hover {
  background-color: #ED2B2A;
  color: rgb(255, 255, 255);
  border-radius: 0%;
}

.deleviry-model-2-btn-2 {
  width: 148px;
  height: 100%;
  border-right: 2px solid #ED2B2A;
  text-align: center;
  padding-top: 5px;
  background-color: #ED2B2A;
  color: white;
  text-decoration: none;
  font-size: 17px;
  transition: 0.3s;
}

.search-locationmodel {
  cursor: pointer;
}
.password-input-container {
  position: relative;
}

.password-toggle-icon-login {
  position: absolute !important;
  top: 50% !important ;
  right: 30px !important; 
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
}
.password-toggle-icon {
  position: absolute !important;
  top: 50% !important ;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
}


/* Session-3 Style  */

.session3-main {
  background-color: #F2F2F2;
  height: auto;
  padding-bottom: 60px;
  width: 100%;
}

.main-category-list-session-3 {
  position: sticky;
  top: 120px;
  z-index: 1000;
}

.searchbar-main-session-3 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbar-session-3 {
  width: 80%;
  border-radius: 10px;
  border: 2px solid #006e6b;
  height: 40px;
}

.searchbar-session-3:focus {
  border-color: #006e6b;
  outline: none;
}

.main-category-list-session-3 h3 {
  border-bottom: 1px solid rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  background-color: #ED2B2A;
  color: white;
}


.category-links-1 {
  padding: 6px;
  text-decoration: none;
  color: black;
  width: 100%;
  display: block;
}

.category-links-1:hover {
  color: white;
  background-color: #ED2B2A;
}

.active-link {
  color: white;
  background-color: #ED2B2A;
}
.category-links-1.active {
  font-weight: bold;
  background-color: #ED2B2A;
}


.col-main-session-3-card {
  display: flex;
  justify-content: center;
}

.mobile-screen-category{
  overflow: auto;
  width: 100%;
  background-color: white;
  white-space: nowrap;
}
/* Style for Model Login */
.login-model-main {
  text-decoration: none;
  color: white;
  border: none;
  background-color: #006E6B;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 6px;
  font-size: 15px;
}
.login-model-main:hover{
  background-color: #179184ee;
}
.login-btn-main-div-model {
  margin-top: 20px;
  margin-left: 10px;
}

.register-login-model-link {
  text-decoration: none;
  color: black;
}

.register-login-model-link:hover {
  color: rgb(16, 125, 214);
  text-decoration-line: underline;
}

.text-for-register-model-login {
  font-weight: 400;
  font-size: 18px;
}

/* Style for Footer  */


.top-footermain {
  padding-top: 15px;
  padding-bottom: 25px;
  height: auto;
  width: 100%;
  background-color: #ffffff;
}

.row-footertop {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.coloum-main-image-logo-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.time-footer-open {
  line-height: 8px;
  display: block;
}
.time-footer-close{
  display: block;
  line-height: 8px;
}
.time-footer-open .time{
  color: #000000;
  font-weight: 600;
}
.time-footer-close .time{
  font-weight: 600;
}


.coloum-footer-top-branches {
  height: 250px;
}

.branches-top-Footer .la-map-marker-alt {
  font-size: 28px;
  margin-right: 10px;
}

.branches-top-Footer {
  display: flex;
}

.main-branch-top-footer h3 {
  margin-left: 10px;
}

.branches-top-Footer p {
  margin: 0;
  line-height: 1.3;
  width: 80%;
}

.branches-top-Footer-phone {
  display: flex;
  align-items: center;
}

.branches-top-Footer-email {
  display: flex;
  align-items: center;
}

.branches-top-Footer-phone p {
  padding-top: 10px;
}

.branches-top-Footer-email p {
  padding-top: 10px;
}

.branches-top-Footer-phone .la-phone {
  font-size: 28px;
  margin-right: 10px;
}

.branches-top-Footer-email .la-whatsapp {
  font-size: 28px;
  margin-right: 10px;
} 

.footerlinks-social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.custom-link-footer {
  text-decoration: none;
  color: #006E6B;
  font-size: 36px;
}

.custom-link-footer:hover {
  color: #ED2B2A;
}



/* Bottom Footer Style */
.bottom-footer {
  height: 50px;
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-right-text-bottom-footer {
  display: flex;
  justify-content: center;
  height: 23px;
  font-size: 15px;
  font-weight: 500;

}

.bottom-footer-links {
  text-decoration: none;
  font-weight: 700;
  color: #ED2B2A;
}

.bottom-footer-links:hover {
  text-decoration: none;
  font-weight: 700;
  color: #3f7bd6;
  text-decoration: underline;
}

.models-footer-main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sesion-3-links-add{
  text-decoration: none;
  width: 50px;
  height: 30px;
  color: white;
  background-color: #006E6B;
  font-size: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sesion-3-links-add:hover{
  background-color: #179184ee;
}

/* Buy Now CSS */
.buynow-content{
  padding-top: 100px;
}
.buynow-header-nav{
  display: flex;
  justify-content: center;
  align-items: center;
}
.buynow-mainheader-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}
.buynow-customer-detail-main{
  width: 100%;
  height: 60px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #b4b4b4;
  display: flex;
  justify-content: space-around;
}
.buynow-customer-detail-address{
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #b4b4b4;
  display: flex;
  justify-content: space-around;
}

.main-detail-address{
 padding-top: 15px;
}

.la-check-circle{
  font-weight: 700;
  font-size: 18px;
}
.buynow-customer-detail{
  width: 100%;
}
.detail-summary-column{
  display: flex;
  justify-content: center;
}
.link-edit-user-detail{
  border: 1px solid #b4b4b4;
  height: 30px;
  width: 70px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #000000;
}

.link-edit-user-detail:hover{
  border: 1px solid #b4b4b4;
  background-color: #006e6b;
  color: #ffffff;
}

.form-check-input{
  height: 20px;
  width: 20px;
}
.form-check-label{
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
}
.buynow-customer-detail-order-summary{
  width: 100%;
  margin-top: 60px;
}
.order-summary{
  max-height: 700px; 
    overflow-y: auto;
}
.input-forspecial-instruction-buynow{
  width: 100%;
  outline: none;
  border-radius: 5px;
  height: 40px;
  border:1px solid #b4b4b4;
}
.order-item{
  display: flex;
  border:1px solid #b4b4b4;
  border-radius: 5px;
  height: 130px;
  margin-bottom: 10px;
  width: 96%;
  margin-left: 10px;
}
.image-cart-buynow{
  width: 150px;
  height: 128px;
}
.buynow-cart-detail{
  margin-left: 20px;
}
.buynow-cart-detail p{
  line-height: 20px; 

}
.tital-price-buynow-yourcart{
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  
}
.price-total-tital{
  font-size: 20px;
  font-weight: 500;
}
.price-total-buynow{
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
}
.confirmedorder-link-buynow{
  width: 100%;
  height: 40px;
  margin-bottom: 100px;
  text-decoration: none;
  background-color: #006E6B;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-menu{
  width: 100%;
  height: 920px;
  }

  .buynow-main-tital{
    font-size: 25px;
    font-weight: 600;
  }
.order-tital-buynow{
  font-size: 20px;
  font-weight: 500;
}

.sesion-3-links-like {
  background: none;
  border: none;
  cursor: pointer;
}

.sesion-3-links-like.liked .las-heart {
  color: red;
}

.sesion-3-links-like .las-heart-o {
  color: grey;
}
.menubranches-link{
  text-decoration: none;
  color: #000000;
  margin-left: 25px;
}
.home-menu-links{
  width: 135px;
  display: flex;
  justify-content: space-between;
}

.category-cards-tital{
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
}

/* Style for responsive websight */

@media only screen and (min-width: 320px) {

  .main-menu-stack{
    width: 290px;
    display: flex;
    justify-content: space-between;
  }
  .logo-image-header-main {
    width: 250px;
  }

  .la-shopping-cart {
    font-size: 20px;
  }

  .topheader-social-icons h4 {
    font-size: 15px;
  }

  .custom-link {
    font-size: 20px;
  }

  .Addtocart-header2 {
    display: none;
  }

  .header-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header-links-1 {
    display: none;
  }

  .Header-links-2 {
    padding-top: 5px;
    padding-bottom: 5px;

  }

  .courselimage-1 {
    height: 600px;
  }

  .courselimage-2 {
    height: 600px;
  }

  .courselimage-3 {
    height: 600px;
  }

  .courselimage-4 {
    height: 600px;
  }

  .courselimage-5 {
    height: 600px;
  }

  .courselimage-6 {
    height: 600px;
  }

  .coursel-Text {
    height: 280px;
    width: 260px;
  }

  .coursel-2text-1 {
    font-size: 15px;
    font-weight: 700;
  }

  .coursel-2text-2 {
    font-size: 50px;
    line-height: 45px;
    width: 260px;
  }

  .button-coursel {
    width: 260px;
  }

  .button-coursel .btn-main-coursel {
    width: 140px;
    height: 40px;
    font-size: 12px;
  }

  .button-coursel .btn-main-coursel:hover {
    width: 140px;
    font-size: 12px;
  }

  .session2-time {
    width: 300px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .address-main {
    margin-bottom: 10px;
    width: 300px;
    font-size: 10px;
  }

  .address-col p {
    padding-top: 15px;
    font-size: 16px;
  }

  .delivary-main {
    width: 300px;
  }

  .main-category-list-1-session-3 {
    display: none;
  }
  .buynow-customer-detail-main{
    height: auto;
    display: block;
  }
  .main-detail-nameemailphone{
    display: block;
    width: 100%;
  }
  .buynow-customer-detail-address{
    margin-top: 20px;
    display: block;
  }
  .main-detail-address{
    width: 100%;
  }
  .buynow-customer-detail-main p{
    color: #000000;
    padding-top: 0px;
  }
  .session-3-links{
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin-bottom: 5px;
  }
  
  
  .sesion-3-links-like{
    font-size: 16px;
    color: #ffffff;
    background-color: #ED2B2A;
    text-decoration: none;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-card-session-3 {
    width: 160px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  .session-3-card-text-tital h6 {
    margin-top: 10px;
    line-height: 10px;
    font-size: 10px;
    font-weight: 700;
  }
  .session-3-card-text-tital{
    height: 105px;
  }
  .user-sight-card-price {
    height: 23px;
    line-height: 13px;
    color: #000000;
    font-size: 10px;
  }
  .user-sight-card-detail{
    height: 40px;
    margin: 0;
    line-height: 13px;
    color: #000000;
    font-size: 10px;
  }
  .category-links-2 {
    padding: 10px;
    text-decoration: none;
    color: #000000;
    display: inline-block;
  }
  .card-first-hiogh-screen {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    border-radius: 5px;
  }
  .card-btn-tital-text-first {
    width: 50%;
    margin-left: 10px;
  }

  .category-links-2:hover {
    background-color: #ED2B2A;
    color: white;
  }

  .main-category-list-session-3 {
    z-index: 0;
  }

  .text-for-register-model-login {
    font-weight: 400;
    font-size: 14px;
  }

  .image-logo-footer {
    height: 260px;
  }

  .copy-right-text-bottom-footer {
    height: 23px;
    font-size: 9px;
    font-weight: 500;
  }

  .bottom-footer-links {
    font-weight: 700;
    color: #ED2B2A;
  }

  .bottom-footer-links:hover {
    font-weight: 700;
    color: #3f7bd6;
    text-decoration: underline;
  }
  .input-phoneno-model-signup{
    width:330px;
    margin-right: 10px;
  }
  /* Buy Now CSS */
.buynow-mainheader-div{
  display: flex;
  justify-content: center;
}
.pdf-menu{
  width: 100%;
  height: 920px;
  }
  .main-div-iframepdfshow{
    padding-top: 100px;
    font-size: 20px;
    text-align: center;
  }
  .coloum-footer-top-social {
    height: 150px;
  }

  .coloum-footer-top-time {
    height: 250px;
  }
}

@media only screen and (min-width: 375px) {
  .logo-image-header-main {
    width: 320px;
  }

  .la-shopping-cart {
    font-size: 20px;
  }

}

@media only screen and (min-width: 500px) {

  .main-menu-stack{
    width: 300px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
  }
  .custom-link {
    font-size: 25px;
  }

  .coursel-Text {
    height: 300px;
    width: 330px;
  }

  .coursel-2text-1 {
    font-size: 18px;
  }

  .coursel-2text-2 {
    font-size: 70px;
    line-height: 60px;
    width: 300px;
  }

  .session-3-container-main {
    width: 90%;
  }
  .image-card-session-3 {
    width: 230px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  .session-3-card-text-tital h6 {
    margin-top: 12px;
    line-height: 12px;
    font-size: 15px;
    font-weight: 700;
  }
  .user-sight-card-price {
    line-height: 20px;
    font-size: 12px;
  }

  .button-coursel {
    height: 80px;
    width: 300px;
  }

  .text-for-register-model-login {
    font-weight: 400;
    font-size: 18px;
  }

  .copy-right-text-bottom-footer {
    height: 23px;
    font-size: 12px;
    font-weight: 500;
  }

  .bottom-footer-links {
    font-weight: 800;
    color: #ED2B2A;
  }

  .bottom-footer-links:hover {
    text-decoration: none;
    font-weight: 800;
    color: #3f7bd6;
    text-decoration: underline;
  }
  .input-phoneno-model-signup{
    width:100%;
  }
}
@media only screen and (min-width: 576px) {
  .coloum-footer-top-social {
    height: 250px;
  }
}
@media only screen and (min-width: 768px) {


  .topheader-social-icons h4 {
    font-size: 18px;
  }

  .Addtocart-header2 {
    display: block;
  }

  .Header-links-1 {
    display: block;
    margin-left: 20px;
  }

  .Header-links-2 {
    display: none;
  }

  .Addtocart-header1 {
    display: none;
  }

  .courselimage-1 {
    height: 700px;
  }

  .courselimage-2 {
    height: 700px;
  }

  .courselimage-3 {
    height: 700px;
  }

  .courselimage-4 {
    height: 700px;
  }

  .courselimage-5 {
    height: 700px;
  }

  .courselimage-6 {
    height: 700px;
  }

  .coursel-Text {
    height: 400px;
    width: 500px;
  }

  .coursel-2text-1 {
    font-size: 23px;
    font-weight: 700;
  }

  .coursel-2text-2 {
    font-size: 100px;
    line-height: 90px;
    width: 500px;
  }

  .button-coursel {
    width: 500px;
  }

  .session2-time {
    font-size: 16px;
  }

  .address-col p {
    font-size: 15px;
    font-weight: 600;
  }

  .delivary-main {
    font-size: 15px;
    font-weight: 600;
  }

  .main-category-list-2-session-3 {
    display: none;
  }

  .main-category-list-session-3 {
    z-index: 0;
  }
  .session-3-links{
    width: 100%;
  }

  .image-card-session-3 {
    width: 140px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  .session-3-card-text-tital h6 {
    margin-top: 11px;
    line-height: 10px;
    font-size: 10px;
    font-weight: 700;
  }
  .user-sight-card-price {
    height: 23px;
    line-height: 20px;
    color: #000000;
    font-size: 10px;
  }

  .main-category-list-1-session-3 {
    display: block;
    background-color: white;
  }
  .buynow-customer-detail-main{
    height: 60px;
    display: flex;
  }
  .main-detail-nameemailphone{
    display: flex;
    justify-content: space-around;
    width: 60%;
  }
  .buynow-customer-detail-address{
    margin-top: 20px;
    display: flex;
  }
  .main-detail-address{
    width: 60%;
  }
  .buynow-customer-detail-main p{
    color: #000000;
    padding-top: 9px;
  }
  .pdf-menu{
    width: 100%;
    height: 900px;
    }
    .main-div-iframepdfshow{
      padding-top: 80px;
      font-size: 30px;
    }
  }

@media only screen and (min-width: 992px) {
  .image-logo-footer {
    height: 200px;
  }

  .coloum-main-image-logo-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .copy-right-text-bottom-footer {
    height: 23px;
    font-size: 15px;
    font-weight: 500;
  }
  .image-card-session-3 {
    width: 175px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  .session-3-card-text-tital h6 {
    margin-top: 12px;
    line-height: 12px;
    font-size: 15px;
    font-weight: 700;
  }
  .user-sight-card-price {
    line-height: 24px;
    font-size: 12px;
  }
  .header-container-main{
    width: 85%;
  }
}

@media only screen and (min-width: 1200px) {
  .image-logo-footer {
    height: 120px;
  }
  .image-card-session-3 {
    width: 170px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 1440px) {

  .courselimage-1 {
    height: 750px;
  }

  .courselimage-2 {
    height: 750px;
  }

  .courselimage-3 {
    height: 750px;
  }

  .courselimage-4 {
    height: 750px;
  }

  .courselimage-5 {
    height: 750px;
  }

  .courselimage-6 {
    height: 750px;
  }

  .coursel-Text {
    height: 500px;
    width: 900px;
  }

  .coursel-2text-1 {
    font-size: 25px;
  }

  .address-main {
    width: 360px;
  }

  .coursel-2text-2 {
    font-size: 120px;
    font-weight: 600;
    line-height: 120px;
    width: 900px;
  } 

  .button-coursel {
    width: 550px;
  }

  .button-coursel .btn-main-coursel {
    width: 190px;
    height: 40px;
    font-size: 18px;
  }

  .button-coursel .btn-main-coursel:hover {
    width: 190px;
  font-weight: 500;
  font-size: 18px;
  }

  .session2-time {
    width: 350px;
    font-size: 18px;
  }

  .address-col p {
    font-size: 15px;
    font-weight: 600;
  }
  .session-3-container-main {
    width: 80%;
  }
  .image-card-session-3 {
    width: 170px;
    height: 150px;
  }
  .session-3-links{
    width: 100%;
  }   

  .coloum-main-image-logo-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
  }

  .image-logo-footer {
    height: 200px;
  }

  .coloum-footer-top-branches {
    width: 250px;
    height: 250px;
  }
  .header-container-main{
    width: 80%;
  }
}

@media only screen and (min-width: 1600px) {



  .card-btn-tital-text-first {
    width: 80%;
    height: 100%;
    padding-bottom: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .user-sight-card-price {
    font-size: 12px;
  }
  /* .user-sight-card-detail{
    font-size: 12px;
    line-height: 12px;
  } */

  .session-3-card-text-tital h6 {
    margin-top: 6px;
    line-height: 20px;
    font-size: 12px;
    height: 12px;
    font-weight: 800;
  }
  .session-3-container-main {
    width: 80%;
  }

  .coloum-main-image-logo-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
  }

  .image-logo-footer {
    height: 200px;
  }

  .coloum-footer-top-branches {
    width: 280px;
    height: 250px;
  }
  .image-card-session-3 {
    width: 175PX;
    height: 155px;
  }
}


