/* Style for Admin Sight */
body {
    font-family: "Work Sans", sans-serif;
}

/* Style for Admin Login Form Page */

.login-admin-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.login-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}

.login-content form {
    padding-top: 40px;
    height: 85%;
}

.Admin-login-main {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 20px;
}

.input-container {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
}

.input-container input {
    width: 100%;
    padding: 10px;
    border: none;
    background: transparent;
    color: white;
    border-bottom: 2px solid white;
    outline: none;
}

.input-container input:focus {
    outline: none;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: 0.2s ease all;
    color: white;
    pointer-events: none;
}

.input-container input:focus~label,
.input-container input:valid~label {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #ffffff;
}

.login-content button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007F73;
    border: 2px solid #007F73;
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;
    width: 100px;
}

.login-content button:hover {
    background-color: #e4d7d700;
    border: 2px solid #007F73;
    color: #007F73;
    font-weight: 700;
}

.admin-login-logo {
    height: 55px;
}

.main-header-login-admin-logo {
    background-color: white;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-main-login-admin {
    margin-top: 45px;
}

.password-container {
    position: relative;
}

.password-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: white;
}

/* Sttyle for admin header */

.top-header-admin-penal {
    border-bottom: 1px solid rgb(201, 201, 201);
}

.main-header-admin-penal {
    height: 95px;
    display: flex;
    top: 0;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0px 1px 4px 0px #8a8a8a;
    z-index: 1000;
    background-color: #ffffff;
}

.header-admin-penal {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-admin-penal-header {
    height: 60px;
}

.main-profile-admin-penal {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 200px;
}


.profile-image {
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-profile {
    display: flex;
    justify-content: space-between;
    height: 20px;
}

.admin-name {
    display: flex;
    margin-left: 20px;
    align-items: center;
    padding-top: 18px;
    font-weight: 600;
}

/* Style for Admin Aside MEnu  */

/* Style for Admin Penal Side Bar Button  */

.loptop-screen-button-toggle {
    transition: margin-left 0.3s;
    width: 75px;
    z-index: 10000;
}

.side-bar-button-toggle {
    cursor: pointer;
    color: rgb(0, 0, 0);
    padding: 10px;
    border: none;
    margin-top: 110px;
    width: 50px;
    border-radius: 10px;
    margin-left: 10px;
    font-size: 25px;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.side-bar-button-toggle:hover {
    background-color: #007F73;
    color: white;
}

/* Style for Admin penal side bar open */

.leftasidemenu-open {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 175px;
    margin-left: 10px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.active,
.leftasidemenu-open:hover {
    background-color: #ffffff;
}

#links {
    text-decoration: none;
    color: #000000;
}

#menuasidemain i {
    font-size: 22px;
}

#menuasidemain {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 175px;
}

#asidemain1 {
    display: flex;
    align-items: center;
    height: 50px;
    padding-top: 20px;
    width: 110px;
}

.main-open-side-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;
}

.count-order-show {
    background-color: #ff0000;
    color: #fff;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    position: absolute;
    right: 10px;
}

/* Style for Admin penal side bar close */

.leftasidemenu-closed {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 53px;
    margin-left: 10px;
    border-radius: 10px;
}

.active,
.leftasidemenu-closed:hover {
    background-color: #ffffff;
}

#imain-close-menu {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
}

#imain-close-menu i {
    font-size: 22px;
}

.main-close-side-bar-leptop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
}

.count-order-show-closemenu {
    background-color: #ff0000;
    color: #fff;
    border-radius: 50%;
    padding: 1px 6px;
    font-size: 10px;
    position: absolute;
    right: 0px;
}

/* Style for admin penal tab to mobile screens */

.leftasidemenu-closed-tab-mobile {
    margin-top: 7px;
    margin-bottom: 7px;
    width: 30px;
    margin-left: 5px;
    border-radius: 5px;
}

.active,
.leftasidemenu-closed-tab-mobile:hover {
    background-color: #ffffff;
}

#links-tab-mobile {
    text-decoration: none;
    color: #000000;
}

#imain-close-menu-tab-mobile {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
}

#imain-close-menu-tab-mobile i {
    font-size: 15px;
}

.asidemenu-main-btn-or-menu {
    position: fixed;
}

.main-close-side-bar-mobile-tab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
}

.main-card-col-dashbord {
    width: 50%;
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

/* Style for Admin Penala DashBord */




.main-tital-adminpenal {
    font-size: 30px;
    font-weight: 600;
    margin-left: 20px;
}

.main-container-dashbord-card-graphs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.main-dashbord-graphs-first {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main-dashbord-graphs-second {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 28px;
}

.card-main-body-dashbord {
    padding: 20px;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 20px;
}

.progressCol-dashbord-cards {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressContainer-main-container-dashbord {
    position: relative;
    width: 100px;
    height: 100px;
}

.icons-cards-dashbord {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #eb0000;
}

/* Style for Admin Penal DashBord Graps */

.main-graph-container-adminpenal-dashbord {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    height: 315px;
}

.graph-header-dashbord {
    display: flex;
    width: 450px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.graph-tital-dashbord {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.graph-btn-dashbord-slect {
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.main-chart-container-graph-dashbor {
    width: 450px;
    height: 230px;
}

/* style for admin penal dashbord btns */

.main-links-dashbord {
    margin-bottom: 30px;
    margin-top: 10px;
}

.link1-main-dashbord {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px;
    margin: 10px;
    border-radius: 8px;
    background-color: #007F73;
}

.link1-main-dashbord:hover {
    background-color: #179184ee;
}

/* Style for Admin peanl Dashbord Favourits items */

.col-card-faveriout-items {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.dashbord-favourite-items-main {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    margin-bottom: 20px;
}

.dashbord-favourite-item-tital-main {
    padding: 10px;
    font-size: 1.5rem;
}

.main-card-favrioute-items-dashbord {
    width: 90%;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.faveriouts-items-card-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    overflow: hidden;
}

.dashbord-card-tital-likes {
    width: 47%;
}

.dashbord-card-tital-likes .faveriouts-item-tital {
    margin-top: 10px;
}

.dashbord-card-tital-likes i {
    font-size: 20px;
    color: #eb0000;
}

.dashbord-card-tital-cart {
    width: 47%;
}

.dashbord-card-tital-cart .faveriouts-item-tital {
    margin-top: 10px;
}

.dashbord-card-tital-cart i {
    font-size: 25px;
    color: #444444;
}

.card-faveriouts-items-likes {
    display: flex;
    justify-content: space-around;
    width: 70px;
}

.card-faveriouts-items-likes p {
    font-size: 15px;
}

.card-faveriouts-items-cart {
    display: flex;
    justify-content: space-around;
    width: 70px;
}

.card-faveriouts-items-cart p {
    font-size: 15px;
}

.la-cart-arrow-down {
    font-size: 26px;
    color: #000000;
}

.card-graph-small {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 55px;
}

.view-more-btn-for-faveriout-cards {
    width: 130px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.view-more-btn-for-faveriout-cards .la-angle-down {
    margin-top: 1px;
    font-size: 20px;
    font-weight: 900;
}

.view-more-btn-for-faveriout-cards:hover {
    cursor: pointer;
    color: #6d6c6c;
}

.view-more-faverioute-main {
    display: flex;
    justify-content: center;
}

/* Style for Adfmin Penal Orders */

.main-order-cards {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    width: 95%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
}

.adminpenal-order-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}

.col-order-card {
    display: flex;
    justify-content: center;
}

.input-searchbar-order {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #727272;
}

.input-searchbar-order:focus {
    outline: none;
    border: 1px solid #007F73;
}

.main-row-search-bar {
    display: flex;
    justify-content: space-between;

}

.main-coloum-search-bar-btns {
    display: flex;
    justify-content: space-around;
}

.links-order-component {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 500;
    padding: 11px;
    width: 130px;
    text-align: center;
    border-radius: 8px;
    background-color: #007F73;
}

.links-order-component:hover {
    background-color: #179184ee;
}

.table-oprder-list {
    border-radius: 8px;
}

.mobile-screenmain-coloum {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-order-card {
    border: 1px solid #d6d6d6;

    border-radius: 8px;
    padding: 16px;
    height: 100%;
    background-color: #fff;
}

.main-row-order-list-mobile-screen {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.main-th-action {
    width: 150px;
}

.tr-order-history-table {
    position: sticky;
    top: 95px;
}

.tr-order-history-table th {
    background-color: #ececec;
    text-align: center;
}

/* Style for responsive admin penal */

/* Styling for order status */
.order-status {
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.order-status.pending {
    background-color: rgba(0, 225, 255, 0.322);
    color: rgb(0, 153, 255);
}

.order-status.completed {
    background-color: rgba(0, 128, 0, 0.253);
    color: green;
}

.order-status.canceled {
    background-color: rgba(255, 0, 0, 0.247);
    color: red;
}
.order-name-qty-th{
    width: 200px;
}
.order-detail-admin-sight{
    border-bottom: 1px solid #8b8b8b83;
    border-top: 1px solid #8b8b8b83;
    border-left: 1px solid #8b8b8b83;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
}

.dish-qty-order-adminsight{
    width: 20px;
    background-color: #d3d3d3;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Other existing styles */

.customer-name {
    font-size: 15px;
    font-weight: 700;
    line-height: 10px;
    margin: 0;
    padding: 0;
}

.order-time {
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    color: #6d6c6c;
    padding: 0;
    margin: 0;
}

.order-name-time-mobile {
    display: flex;
    justify-content: space-between;
}

.amount-btns-order-mobile {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.order-monile-pickupdelevery-addressbranch {
    height: 50px;
}

.order-pickup-delivery-mobile {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    color: #424242;
    padding: 0;
}

.order-address-branch-mobile {
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    color: #424242;
    padding: 0;
    margin: 0;
}

.action-btns-order-list {
    width: 100px;
}

.action-btn-mobile-screen-order-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
}


.order-action-links-tick .la-edit {
    font-size: 20px;
}


.order-action-links-crose .la-trash {
    font-size: 20px;
}

.action-btn-mobile-screen-order-list .order-action-links-done {
    text-decoration: none;
    color: white;
    border: 1px solid #007F73;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-weight: 400;
    border-radius: 5px;
    background-color: #007F73;
}

.main-row-order-history {
    margin-bottom: 10px;
}



/* Stle for admin penal Dishes */
.category-delete-icon {
    font-size: 20px;
    text-decoration: none;
    color: #eb0000;
}

.category-slider {
    width: 98%;
}


.category-main-dishes-tital {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.col-main-dishes-category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.category-text-main-dishes {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: center;
}

.category-text-main-dishes p {
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    padding-top: 11px;
}

.row-main-category-dishes {
    height: 100%;
    padding-bottom: 30px;
}

.row-main-dishes-searchbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.link-dishes-search-bar {
    text-decoration: none;
    color: white;
    background-color: #007F73;
    font-size: 16px;
    font-weight: 500;
    padding: 11px;
    border-radius: 8px;
}

.link-dishes-search-bar:hover {
    background-color: #179184ee;
}

.sabcategory-dishes-main {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sabcategory-dishes {
    border-radius: 8px;
    width: 100%;
}



.main-coloum-sabcategroy-cards {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sabcategory-image img {
    height: 150px;
    width: 230px;
    border-radius: 8px;
    object-fit: cover;
}

.sabcategory-titalordata {
    width: 50%;
    height: 150px;
    display: flex;
    align-items: center;
}

.sabcategory-card-titalamount-likes {
    height: 130px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.card-tital-likes-price {
    width: 80%;
    height: 65px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.sabcategroy-card-graph {
    display: flex;
    justify-content: space-around;
}

.sabcategory-card-edit-main {
    display: flex;
    justify-content: end;
    width: 50%;
    align-items: end;
    height: 100%;
}

.edit-button-sabcategory {
    background-color: #F3CA52;
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
    border: none;
}

.edit-button-sabcategory:hover {
    background-color: #F6E96B;
}

.delete-button-sabcategory {
    background-color: #eb0000;
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.delete-button-sabcategory:hover {
    background-color: #ff3333ec;
}

.card-tital-likes-price p {
    font-weight: 500;
    font-size: 18px;
}



/* Style for admin penal Add dishes component */
.content-component-adddishes {
    display: flex;
    justify-content: center;
}

.main-adddisesform-admin-penal {
    display: flex;
    justify-content: center;
    width: 70%;
    padding: 20px;
    border: 1px solid #d6d6d6;
}

.main-adddishbefore-row {
    display: flex;
    justify-content: center;
}

.rowmain-form-adddishyes {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70vh;
}

.main-adddisesform-admin-penal .tital-add-dishes {
    text-align: center;
    font-size: 25px;
    margin-right: 100px;
    font-weight: 600;
}

.adddish-coloum-text p {
    width: 70%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    height: 200px;
}

.new-categoryadd-adddishes {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: #007F73;
    height: 40px;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    border: none;
}

.new-categoryadd-adddishes:hover {
    background-color: #179184ee;
}

.form-submit-link-adddishes {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: #007F73;
    height: 40px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    border: none;
}

.form-submit-link-adddishes:hover {
    background-color: #179184ee;
}

/* Srtyle for Admin Penal User Information */

.th-action-userinfo {
    width: 180px;
}

.th-accountcreationdate-userinfo {
    width: 250px;
}


.amount-btns-userinfo-mobile {
    display: flex;
    justify-content: end;
    height: 40px;
    align-items: center;
}

.action-btn-mobile-screen-userinfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.order-action-links-tick {
    text-decoration: none;
    color: white;
    width: 40px;
    text-align: center;
    padding: 6px;
    font-weight: 800;
    border-radius: 5px;
    background-color: #F3CA52;
    border: none;
}

.order-action-links-tick:hover {
    background-color: #F6E96B;
}

.order-action-links-crose {
    text-decoration: none;
    color: white;
    padding: 8px;
    width: 40px;
    text-align: center;
    border-radius: 5px;
    background-color: #eb0000;
}

.order-action-links-crose:hover {
    background-color: #ff3333ec;
}

.total-order-container {
    background-color: #f0f0f0;
    padding: 10px;
    height: 50px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.total-order {
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
}

.model-Addcategory {
    width: 100%;
    margin-top: 0px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-link-save-addcategory {
    width: 85%;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #f2f2f2;
    border: 2px solid #007F73;
    background-color: #007F73;
    font-size: 18px;
    font-weight: 400;
}

.model-link-save-addcategory:hover {
    border: 2px solid #006e6ad2;
    background-color: #006e6ad2;
}

.adminprofile-image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.th-admin-profile {
    width: 250px;
}

.admininfo-profile {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.admininfo-profile p {
    width: 150px;
    font-weight: 600;
    font-size: 15px;
    padding-top: 5px;
}

.Adminrole {
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    text-align: center;
}

.Adminrole.Sab-admin {
    background-color: rgba(0, 225, 255, 0.322);
    color: rgb(0, 153, 255);
}

.Adminrole.Super-admin {
    background-color: rgba(0, 128, 0, 0.253);
    color: green;
}

/* Style for Admin PEnal Add new Admin / Users */

.one-form-addnew-adminuser {
    width: 100%;
    height: auto;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-form-addnew-adminuser {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 70%;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
}

.main-rowadmin-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-form-addadminuser {
    margin-bottom: 10px;
}

.input-form-addadminuser-role {
    margin-bottom: 10px;
    width: 55%;
}

.input-form-addadminuser-date {
    margin-bottom: 10px;
    width: 40%;
}

.admin-form-role-date {
    display: flex;
    justify-content: space-between;
}

.password-input-container {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em;
}

.previewImage {
    height: 200px;
}
/* Style for admin penal recipt  */

.recipt-logo{
    height: 60px;
}
.header-recipt-admin-sight-main{
    width: 100%;
    display: flex;
    justify-content: center;
}
.header-recipt-admin-sight{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-detail-recipt-order{
    width: 300px;
}
.main-detail-recipt-order-admin-sight{
    width: 100%;
    display: flex;
    justify-content: center;
}
.admin-sight-recipt-detail{
    display: flex; 
    justify-content: space-between;
}
.detail-order-recipt-adminsight{
    line-height: 8px;
    font-size: 13px;
    font-weight: 500;
}
.tital-recipt-detail-adminsight{
    line-height: 8px;
    font-size: 13px;
    font-weight: 500;
}
.recipt-tr-order-detail{
    width: 300px;
    display: flex;
    justify-content: space-between;
}
@media print {
    .no-print {
        display: none;
    }
}

.form-adminpassword{
    position: absolute;
    top: 5px;
    left: 15px;
    width: auto;
}
  
@media only screen and (min-width: 320px) {

    .Admin-login-main {
        width: 95%;
        height: auto;
        padding: 20px;
    }

    .admin-login-logo {
        height: 40px;
    }

    /* Style for Main Admin side bar */
    #asidemain0-leptop-screen {
        display: none;
        width: 250px;
    }

    /* Style for admin penal side bar open menu */
    .sidebar-tab-screen {
        width: 0;
        height: 100%;
        position: fixed;
        left: 0;
        overflow-x: hidden;
        top: 96px;
    }

    .sidebar-tab-screen.close {
        height: 100%;
        background-color: #e9e9e9;
        width: 30px;
    }

    .logo-admin-penal-header {
        height: 40px;
        margin-left: 10px;
    }

    .main-profile-admin-penal {
        width: 40px;
        display: flex;
        justify-content: center;
    }

    .header-admin-penal {
        width: 95%;
    }

    .main-header-admin-penal {
        display: flex;
        justify-content: space-between;
    }

    .admin-profile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-name {
        display: none;
    }

    .profile-image {
        height: 40px;
    }

    .admin-profile i {
        font-size: 16px;
    }

    .leptop-oruperscreen {
        display: none;
    }

    .leftasidemenu-closed-tab-mobile {
        margin-top: 7px;
        margin-bottom: 7px;
        width: 30px;
        margin-left: 5px;
        border-radius: 5px;
    }

    #links-tab-mobile {
        text-decoration: none;
        color: #000000;
    }

    #imain-close-menu-tab-mobile {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
    }

    #imain-close-menu-tab-mobile i {
        font-size: 15px;
    }

    .content-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .Side-bar-open {
        width: 85%;
        height: 100%;
        margin-left: 12%;
        margin-top: 100px;
        z-index: 0;
    }

    .main-tital-adminpenal {
        font-size: 15px;
        font-weight: 700;
        margin-left: 10px;
    }

    .count-order-show-closemenu {
        background-color: #ff0000;
        color: #fff;
        border-radius: 50%;
        padding: 0px 4px;
        font-size: 8px;
        position: absolute;
        right: 0px;
    }

    /* Style for Qdmin Penal Dash Bord */
    /* Style for Admin Penala DashBord */
    
.react-datepicker{
    position: absolute !important;
    right: -11px;
    width: 170px;
}
    .main-container-dashbord-card-graphs {
        display: block;
        width: 100%;
    }

    .main-graps-dashbords2 {
        display: none;
    }

    .main-dashbord-cards {
        width: 100%;
    }

    .main-card-col-dashbord {
        width: 100%;
        height: 105px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .card-main-body-dashbord {
        padding: 10px;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 0px;
    }

    .progressContainer-main-container-dashbord {
        position: relative;
        width: 70px;
        height: 70px;
    }

    .adminpenal-card-value {
        font-size: 11px;
        font-weight: 500;
    }

    .adminpenal-card-tital {
        font-size: 10px;
        font-weight: 500;
    }

    .icons-cards-dashbord {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        color: #eb0000;
    }

    .main-btns-dashbord {
        display: none;
    }

    .main-graph-container-adminpenal-dashbord {
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .graph-header-dashbord {
        display: flex;
        width: 230px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .graph-tital-dashbord {
        margin: 0;
        font-size: 0.7rem;
        color: #333;
    }

    .main-chart-container-graph-dashbor {
        width: 239px;
        height: 120px;
    }

    .main-dashbord-graphs-second {
        margin-top: 10px;
    }

    .graph-btn-dashbord-slector {
        font-size: 8px;
    }

    .dashbord-favourite-items-main {
        margin-top: 10px;
    }

    .dashbord-favourite-item-tital-main {
        padding: 10px;
        height: 30px;
        font-size: 0.7rem;
    }

    .col-card-faveriout-items {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .main-card-favrioute-items-dashbord {
        width: 95%;
    }

    .faveriouts-items-card-image {
        height: 130px;
        border-radius: 8px;
        overflow: hidden;
    }

    .dashbord-card-tital-likes-graph {
        width: 47%;
    }

    .dashbord-card-tital-likes {
        height: 60px;
        width: 100%;
    }

    .dashbord-card-tital-likes .faveriouts-item-tital {
        margin-top: 5px;
        font-size: 15px;
        margin-left: 5px;
    }

    .dashbord-card-tital-likes i {
        font-size: 15px;
    }

    .dashbord-card-tital-cart {
        height: 60px;
        width: 100%;
    }

    .dashbord-card-tital-cart .faveriouts-item-tital {
        margin-top: 5px;
        font-size: 15px;
        margin-left: 5px;
    }

    .dashbord-card-tital-cart i {
        font-size: 20px;
    }

    .card-faveriouts-items-likes {
        width: 50px;
    }

    .card-faveriouts-items-likes p {
        font-size: 12px;
    }

    .card-faveriouts-items-cart {
        width: 50px;
    }

    .card-faveriouts-items-cart p {
        font-size: 12px;
    }

    .card-graph-small {
        width: 100%;
        height: 45px;
    }

    /* Style for Admin Penal Orders */

    .main-order-cards {
        width: 100%;
        height: 100px;
        margin-top: 10px;
    }

    .container-searchbarone {
        display: none;
    }

    .main-coloum-search-bar-btns {
        display: none;
    }

    .main-row-order-cards {
        margin-bottom: 10px;
    }

    .main-row-search-bar {
        margin-bottom: 15px;
    }

    .table-order-list {
        display: none;
    }

    /* Style for Admin Penal Order list mobile-screen*/
    /* For consistent card height */
    .order-column {
        display: flex;
        flex-direction: column;
    }

    .mobile-order-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 10px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .product-details {
        flex: 1;
    }

    /* Stye for Admin Penal Category Dishes */

    .error-model-category {
        color: #eb0000;
        margin-left: 35px;
    }

    .category-main-dishes-tital {
        text-align: center;
        font-size: 18px;
        height: 10px;
    }

    .category-text-main-dishes {
        width: 89%;
        height: 40px;
    }

    .category-text-main-dishes p {
        font-size: 13px;
        padding-top: 11px;
    }

    .row-main-category-dishes {
        padding-bottom: 0px;
    }

    .row-main-dishes-searchbar {
        height: 70px;
    }

    .col-main-link-dishes {
        display: none;
    }

    .main-coloum-sabcategroy-cards {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .main-sabcategory-card {
        width: 100%;
        height: auto;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        display: block;
    }

    .sabcategory-image img {
        height: 150px;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
    }

    .sabcategory-titalordata {
        width: 100%;
        height: auto;
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .main-graph-sabcategory {
        height: 60px;
    }

    .sabcategory-card-edit-main {
        width: 20%;
        margin-right: 10px;
    }

    /* Style for admin penal Add Dishes */
    .new-categoryadd-adddishes-category {
        width: 100%;
        margin-bottom: 10px;
    }

    .adddish-coloum-text {
        display: none;
    }

    .rowmain-form-adddishyes {
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-bottom: 50px;
    }

    .main-adddisesform-admin-penal {
        display: block;
        width: 100%;
        padding: 5px;
        margin-bottom: 30px;
    }

    .main-adddisesform-admin-penal .tital-add-dishes {
        text-align: center;
        font-size: 15px;
        margin-right: 0px;
        font-weight: 500;
    }

    .main-coloum-form-adddishes {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .adddish-input-lable {
        font-size: 12px;
        font-weight: 400;
    }

    .forms-adddishes-input {
        margin-bottom: 8px;
    }

    .input-category-main {
        display: block;
    }

    .input-category-main .slector-adddishes {
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
    }

    .new-categoryadd-adddishes {
        width: 100%;
        font-weight: 500;
    }

    .form-submit-link-adddishes {
        font-weight: 500;
        margin-top: 15px;
        width: 100%;
    }

    .adddishes-slected-image {
        width: 50%;
        height: auto;
    }

    /* Style for Admin Penal  Admin Information */

    .admininfo-profile {
        display: flex;
        justify-content: space-between;
        width: 200px;
        margin-bottom: 10px;
    }

    .adminprofile-image {
        width: 30px;
        height: 30px;
    }

    /* Style for Admin Penal Add Admin / User */
    .one-form-addnew-adminuser {
        padding: 0px;
    }

    .second-form-addnew-adminuser {
        padding-top: 10px;
        width: 100%;
    }
    /* Style for admin penal recipt  */
    .header-recipt-admin-sight{
        width: 260px;
    }
    .main-detail-recipt-order{
        width: 260px;
    }
    
    .recipt-tr-order-detail{
        width: 260px;
    }
}

@media only screen and (min-width: 400px) {

    .sidebar-tab-screen.close {
        height: 100%;
        width: 45px;
    }

    .logo-admin-penal-header {
        height: 45px;
    }

    .main-profile-admin-penal {
        width: 80px;
    }

    .profile-image {
        height: 45px;
    }

    .admin-profile i {
        font-size: 18px;
    }

    .leftasidemenu-closed-tab-mobile {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 45px;
        margin-left: 8px;
    }

    #links-tab-mobile {
        text-decoration: none;
        color: #000000;
    }

    #imain-close-menu-tab-mobile {
        width: 35px;
    }

    #imain-close-menu-tab-mobile i {
        font-size: 18px;
    }

    /* Style for Admin PEnal dash Bord */
    .main-graph-container-adminpenal-dashbord {
        padding: 10px;
        height: 220px;

    }

    .graph-header-dashbord {
        width: 315px;
    }

    .graph-btn-dashbord-slector {
        font-size: 11px;
    }

    .graph-tital-dashbord {
        font-size: 1rem;
    }

    .main-chart-container-graph-dashbor {
        width: 320px;
        height: 160px;
    }

    .card-graph-small {
        width: 100%;
        height: 55px;
    }

    .dashbord-favourite-item-tital-main {
        padding: 10px;
        height: 40px;
        font-size: 1rem;
    }

}

@media only screen and (min-width: 500px) {

    .Admin-login-main {
        width: 85%;
        padding: 20px;
    }

    .admin-login-logo {
        height: 45px;
    }

    .Side-bar-open {
        width: 90%;
        height: 96%;
        margin-left: 50px;
        margin-right: 7PX;
    }

    .adminpenal-card-value {
        font-size: 14px;
        font-weight: 500;
    }

    .adminpenal-card-tital {
        font-size: 14px;
        font-weight: 500;
    }

    /* Style for Admin Penal Orders */

    .main-order-cards {
        height: 130px;
    }


    /* Style for Admin Penal Dishes Cards */


    .main-sabcategory-card {
        width: 100%;
        height: auto;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
    }

    .sabcategory-image img {
        height: 150px;
        width: 200px;
        border-radius: 8px;
        object-fit: cover;
    }

    .sabcategory-titalordata {
        width: 80%;
    }
      /* Style for admin penal recipt  */
      .header-recipt-admin-sight{
        width: 300px;
    }
    .main-detail-recipt-order{
        width: 300px;
    }
    
    .recipt-tr-order-detail{
        width: 300px;
    }
}
.form-submit-link-adddishes:disabled {
    background-color: #6c757d; 
    cursor: not-allowed; 
    opacity: 0.65;
}

@media only screen and (min-width: 768px) {

    .Admin-login-main {
        width: 70%;
        padding: 20px;
    }

    .admin-login-logo {
        height: 45px;
    }

    .sidebar-tab-screen.close {
        width: 55px;
    }

    .logo-admin-penal-header {
        height: 55px;
    }

    .header-admin-penal {
        width: 70%;
    }

    .main-profile-admin-penal {
        width: 120px;
    }

    .admin-profile {
        width: 300px;
        height: 100%;
        margin-right: 0;
        display: flex;
        align-items: center;
    }

    .admin-name {
        display: flex;
    }

    .admin-name p {
        display: block;
    }

    .main-header-admin-penal {
        display: flex;
        justify-content: center;
    }

    .admin-profile i {
        font-size: 23px;
    }

    .leftasidemenu-closed-tab-mobile {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 55px;
        margin-left: 10px;
    }

    .count-order-show-closemenu {
        background-color: #ff0000;
        color: #fff;
        border-radius: 50%;
        padding: 1px 6px;
        font-size: 10px;
        position: absolute;
        right: 0px;

    }

    #imain-close-menu-tab-mobile {
        width: 48px;
        height: 50px;
    }

    #imain-close-menu-tab-mobile i {
        font-size: 22px;
    }

    .Side-bar-open {
        width: 100%;
        height: 96%;
        margin-left: 65px;
        margin-right: 10px;
    }

    .main-tital-adminpenal {
        font-size: 25px;
        font-weight: 600;
        margin-left: 15px;
    }

    /* Style for Admin Penal DashBord */
    .main-card-col-dashbord {
        width: 50%;
        height: 105px;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .main-graps-dashbords1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .main-graph-container-adminpenal-dashbord {
        width: 97%;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 220px;
    }

    .main-dashbord-graphs-second {
        margin-top: 0px;
    }

    .main-btns-dashbord {
        display: block;
    }

    .main-links-dashbord {
        margin-bottom: 15px;

    }

    .main-card-favrioute-items-dashbord {
        width: 98%;
    }

    /* Style for Admin Penal Orders */

    .container-searchbarone {
        display: block;
    }

    .container-searchbarsecond {
        display: none;
    }

    .main-coloum-search-bar-btns {
        display: flex;
        justify-content: space-between;
    }

    .main-row-order-cards {
        margin-bottom: 10px;
    }

    .main-row-search-bar {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    /* Stye for Admin Penal Category Dishes */
    .category-main-dishes-tital {
        text-align: center;
        font-size: 20px;
        height: 18px;
    }

    .category-text-main-dishes {
        width: 89%;
        height: 40px;
    }

    .category-text-main-dishes p {
        font-size: 13px;
        padding-top: 11px;
    }

    .row-main-dishes-searchbar {
        height: 80px;
    }

    .col-main-link-dishes {
        display: block;
    }

    /* Style for Admin Penal Dishes Cards */


    .main-sabcategory-card {
        width: 100%;
        height: auto;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
    }

    .sabcategory-image img {
        height: 150px;
        width: 150px;
        border-radius: 8px;
        object-fit: cover;
    }

    .sabcategory-titalordata {
        width: 50%;
    }

    .main-graph-sabcategory {
        height: 50px;
    }

    .sabcategory-card-edit-main {
        width: 25%;
    }

    /* Style for admin penal Add Dishes */

    .adddish-coloum-text {
        display: none;
    }

    .main-adddisesform-admin-penal {
        padding: 0px;
        height: auto;
    }

    .main-adddisesform-admin-penal .tital-add-dishes {
        text-align: center;
        font-size: 20px;
        padding-top: 10px;
        font-weight: 500;
    }

    .main-coloum-form-adddishes {
        display: flex;
        width: auto;
        justify-content: center;
    }

    .adddish-input-lable {
        font-size: 17px;
        font-weight: 400;
    }

    .forms-adddishes-input {
        margin-bottom: 15px;
        width: 500px;
    }

    .one-form-addnew-adminuser {
        padding: 0px;
    }

    .second-form-addnew-adminuser {
        padding-top: 10px;
        width: 90%;
    }
}

@media only screen and (min-width: 1024px) {

    .Admin-login-main {
        width: 60%;
        padding: 20px;
    }

    .admin-login-logo {
        height: 50px;
    }

    .main-profile-admin-penal {
        width: 300px;
    }

    /* Style for Main Admin side bar */
    #asidemain0-leptop-screen {
        display: block;
    }

    /* Style for Admin Penal side bar open */
    .sidebarclose {
        width: 0;
        height: 100%;
        position: fixed;
        left: 0;
        overflow-x: hidden;
        top: 40px;
        padding-top: 60px;
        transition: all 0.3s ease-in-out;
    }

    .sidebarclose.close {
        width: 175px;
        height: 100%;
        background-color: #e9e9e9;
    }

    /* Style for Admin PEnal Close */
    .sidebaropen {
        width: 0;
        height: 100%;
        position: fixed;
        left: 0;
        overflow-x: hidden;
        padding-top: 60px;
        top: 40px;
        transition: all 0.3s ease-in-out;
    }

    .sidebaropen.open {
        height: 100%;
        background-color: #e9e9e9;
        width: 55px;
    }

    /* Style for Admin penal tab screen  */
    #asidemain0-tab-screen {
        display: none;
    }

    .leptop-oruperscreen {
        display: block;
    }

    .tab-to-mobile-screens {
        display: none;
    }

    .Side-bar-open {
        width: 100%;
        height: 100%;
        margin-left: 230px;
        margin-right: 10px;
        transition: margin-left 0.3s ease-in-out;
        margin-top: 120px;
        z-index: 0;
        padding: 0;
    }

    .Side-bar-closed {
        width: 100%;
        height: 100%;
        margin-left: 120px;
        transition: margin-left 0.3s ease-in-out;
        margin-right: 10px;
        margin-top: 120px;
        z-index: 0;
        padding: 0;
    }


    /* Style for Admin PEnal Dashbod */
    .main-graph-container-adminpenal-dashbord {
        height: 280px;
    }

    .graph-header-dashbord {
        width: 350px;
    }

    .main-chart-container-graph-dashbor {
        width: 350px;
        height: 190px;
    }

    .graph-btn-dashbord-slector {
        font-size: 15px;
    }

    .card-main-body-dashbord {
        padding: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 20px;
    }

    .main-card-col-dashbord {
        width: 50%;
        height: 175px;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .progressContainer-main-container-dashbord {
        position: relative;
        width: 100px;
        height: 100px;
    }

    .adminpenal-card-value {
        font-size: 14px;
        font-weight: 500;
    }

    .adminpenal-card-tital {
        font-size: 16px;
        font-weight: 600;
    }

    .icons-cards-dashbord {
        font-size: 1.5rem;
    }

    /* Style for Admin Penal Orders */

    .main-order-cards {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 15px;
    }

    .main-coloum-search-bar-btns {
        display: flex;
        justify-content: space-between;
        width: 350px;
    }

    .main-row-order-cards {
        margin-bottom: 10px;
    }

    /* Stye for Admin Penal Category Dishes */
    .category-main-dishes-tital {
        text-align: center;
        font-size: 25px;
        height: 30px;
    }

    .category-text-main-dishes {
        width: 89%;
        height: 50px;
    }

    .category-text-main-dishes p {
        font-size: 15px;
        padding-top: 13px;
    }

    .col-main-link-dishes {
        display: flex;
        justify-content: center;
    }

    /* Style for admin penal Add Dishes */
    .adddish-coloum-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
    }

    .main-adddisesform-admin-penal {
        padding: 0px;
    }

    .main-adddisesform-admin-penal .tital-add-dishes {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
    }

    .adddish-input-lable {
        font-size: 17px;
        font-weight: 500;
    }

    .main-coloum-form-adddishes {
        border-left: 1px solid #d6d6d6;
        padding-left: 50px;
    }

    .forms-adddishes-input {
        margin-bottom: 15px;
        width: 380px;
    }
}

@media only screen and (min-width: 1300px) {
    .mobile-view {
        display: none;
    }

    .table-order-list {
        display: block;
        margin-top: 30px;
    }

    .main-tital-adminpenal {
        font-size: 30px;
        font-weight: 600;
        margin-left: 20px;
    }

    .main-graph-sabcategory {
        height: 55px;
    }

    .sabcategory-card-edit-main {
        width: 30%;
    }

    /* Style for admin penal Add Dishes */
    .new-categoryadd-adddishes-category {
        width: 65%;
    }

    .adddish-input-lable {
        font-size: 20px;
    }

    .forms-adddishes-input {
        margin-bottom: 15px;
        width: 450px;
    }

    .main-adddisesform-admin-penal {
        height: auto;
        width: 85%;
    }

    .input-category-main {
        display: flex;
        justify-content: space-between;
    }

    .input-category-main .slector-adddishes {
        width: 65%;
        margin-bottom: 0;
        font-size: 18px;
    }

    .new-categoryadd-adddishes {
        width: 30%;
    }

    .one-form-addnew-adminuser {
        padding: 10px;
    }

    .second-form-addnew-adminuser {
        padding-top: 25px;
        width: 70%;
    }

    .admininfo-profile p {
        width: 150px;
        font-weight: 600;
        font-size: 15px;
        padding-top: 10px;
    }

}

@media only screen and (min-width: 1400px) {

    .Admin-login-main {
        width: 40%;
        padding: 20px;
    }

    .admin-login-logo {
        height: 50px;
    }

    .Side-bar-open {
        margin-left: 240px;
        margin-right: 20px;
    }

    .Side-bar-closed {
        margin-left: 150px;
        margin-right: 20px;
    }

    /* Style for Admin Penal Dashbord */
    .mobile-screen-graphs {
        display: none;
    }

    .main-dashbord-cards {
        width: 68%;
    }

    .main-graps-dashbords2 {
        display: block;
        width: 27%;
        position: fixed;
        right: 10px;
        top: 177px;
    }


    /* Style for admin penal Add Dishes */

    .main-adddisesform-admin-penal {
        width: 75%;
    }

    .input-category-main {
        display: flex;
        justify-content: space-between;
    }

    .input-category-main .slector-adddishes {
        width: 65%;
        margin-bottom: 0;
        font-size: 18px;
    }

    .new-categoryadd-adddishes {
        width: 30%;
    }
}

@media only screen and (min-width: 1600px) {

    .Admin-login-main {
        width: 30%;
        padding: 20px;
    }

    .admin-login-logo {
        height: 50px;
    }

    /* Style for Admin Penal Dash Bord */

    .main-graph-container-adminpenal-dashbord {
        width: 97%;
        padding: 15px;
        height: 280px;
    }

    .main-chart-container-graph-dashbor {
        width: 400px;
        height: 210px;
    }

    .graph-header-dashbord {
        width: 400px;
    }

    .main-graph-sabcategory {
        height: 60px;
        margin-top: 10px;
    }

    .sabcategory-card-edit-main {
        width: 40%;
    }


    /* Style for Admin Penal Dishes Cards */

    .sabcategory-image img {
        height: 180px;
        width: 200px;
        border-radius: 8px;
        object-fit: cover;
    }
}